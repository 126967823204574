import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Dashboard.scss'
import { Outlet } from 'react-router-dom'
import { useStateContext } from '../../Context/ContextProvider'
import PageSpinner from '../PageSpinner/PageSpinner'
import SmsVerification from '../SmsVerification/SmsVerification'

const Dashboard = () => {
  const { isLoading, me } = useStateContext()
  return (
    <>
      {isLoading ? (
        <PageSpinner />
      ) : me.status === 'UNVERIFIED' ? (
        <SmsVerification />
      ) : (
        <div className="dashboard">
          <Sidebar />
          <Outlet />
        </div>
      )}
    </>
  )
}

export default Dashboard
