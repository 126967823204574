import { useParams } from 'react-router-dom'
import { useStateContext } from './ContextProvider'

export const useRouteHandler = () => {
  const {
    activeDist,
    setActiveDist,
    setReloadCurrentDist,
    activeOrigin,
    setActiveOrigin,
    activeCache,
    setActiveCache,
  } = useStateContext()
  const params = useParams()

  if (params.distributionId) {
    const distributionId = params.distributionId
    if (!activeDist) {
      setActiveDist(distributionId)
      setReloadCurrentDist(true)
    } else if (activeDist !== distributionId) {
      setActiveDist(distributionId)
    }
  }
  if (params.originId) {
    const originId = params.originId
    if (activeOrigin !== originId) {
      setActiveOrigin(originId)
    }
  }
  if (params.cacheId) {
    const cacheId = params.cacheId
    if (activeCache !== cacheId) {
      setActiveCache(cacheId)
    }
  }
}

export const routeHandler = (stateContext, params) => {
  if (params.distributionId) {
    const distributionId = params.distributionId
    if (!stateContext.activeDist) {
      stateContext.setActiveDist(distributionId)
      stateContext.setReloadCurrentDist(true)
    } else if (stateContext.activeDist !== distributionId) {
      stateContext.setActiveDist(distributionId)
    }
  }
  if (params.originId) {
    const originId = params.originId
    if (stateContext.activeOrigin !== originId) {
      stateContext.setActiveOrigin(originId)
    }
  }
  if (params.cacheId) {
    const cacheId = params.cacheId
    if (stateContext.activeCache !== cacheId) {
      stateContext.setActiveCache(cacheId)
    }
  }
}
