import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const CreateOrigin = () => {
  const { activeDist, setReloadCurrentDist } = useStateContext()
  const navigate = useNavigate()

  const [{ error }, originCreate] = useMutation(
    buildMutationQuery('originCreate', ['success', { origin: ['id'] }])
  )

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const onSubmit = async input => {
    const result = await originCreate({
      input: {
        ...input,
        distributionId: activeDist,
      },
    })

    if (result?.data?.originCreate?.success) {
      navigate(
        `/distributions/${activeDist}/origins/${result.data.originCreate.origin.id}/edit`
      )
      setReloadCurrentDist(true)
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
      <SubscriptionGuard type="form">
        <h3 className="content-subtitle">Create New Origin</h3>
        <p className="content-text">
          Your origin can be any server/service with public domain name or for
          example any publicly accessible AWS service, like S3 bucket, elastic
          load balancer. Your FlashEdge distribution than pulls and caches
          content from the origin that you choose.
        </p>
        <div className="input-wrap">
          <label className="input-label">Origin Name (origin-1)</label>
          <input
            className="input"
            type="text"
            placeholder="Fill Your Origin Name"
            required
            {...register('name', { required: true })}
          ></input>
        </div>
        <div className="input-wrap">
          <label className="input-label">Fill Your Domain Name</label>
          <input
            className="input"
            type="text"
            placeholder="Fill Your Domain Name"
            required
            {...register('domainName', { required: true })}
          ></input>
        </div>
        <div className="input-wrap">
          <label className="input-label">Origin Path (optional)</label>
          <input
            className="input"
            type="text"
            placeholder="Fill Your Path (/app, /pics, etc..)"
            {...register('path', { required: false })}
          ></input>
        </div>
        <h3 className="content-subtitle">Protocol Policy</h3>
        <p className="content-text">
          The origin protocol policy determines the protocol (HTTP or HTTPS)
          that you want FlashEdge to use when connecting to the origin. You can
          choose the following options:
        </p>

        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="http-only"
            type="radio"
            value="HTTP_ONLY"
            required
            {...register('protocolPolicy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="http-only">
            <span className="bold-selected">HTTP Only</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="https-only"
            type="radio"
            value="HTTPS_ONLY"
            required
            {...register('protocolPolicy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="https-only">
            <span className="bold-selected">HTTPS Only</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="match-viewer"
            type="radio"
            value="MATCH_VIEWER"
            required
            {...register('protocolPolicy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="match-viewer">
            <span className="bold-selected">Match Viewer</span>
            <br />
          </label>
        </div>
        <br />
        {error && (
          <p className={`content-text`}>
            <span className="warning">
              {error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
        )}
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Create Origin
          </button>
          <Link to={`/distributions/${activeDist}/origins/`}>
            <button className="theme-btn ml-auto-20" type="button">
              Go Back
            </button>
          </Link>
        </div>
      </SubscriptionGuard>
    </form>
  )
}

export default CreateOrigin
