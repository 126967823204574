import React, { createContext, useContext, useEffect, useState } from 'react'
import { gql, useQuery, useSubscription } from 'urql'
import { useAuthContext } from './AuthContext'
import { buildQueryById } from '../client'
import { config } from '../config'
import dummyData from '../data'

const StateContext = createContext()

const MeQuery = gql`
  query Me {
    me {
      __typename
      id
      email
      status
      firstName
      lastName
      role
      account {
        __typename
        id
        # billingEmail
        # email
        firstName
        lastName
        street
        city
        zip
        countryCode
        # currencyCode
        companyName
        vatId
        status
        subscription {
          paymentMethod
          status
          type
          interval
          periodStart
          periodEnd
          billingAt
          endedAt
        }
      }
    }
  }
`

const DistributionListQuery = gql`
  query DistributionList($limit: Int, $nextToken: String) {
    distributionList(limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        name
        domainName
        pricingRegion
        status
        usage {
          totalPrice
        }
      }
      nextToken
    }
  }
`

const DistributionOnUpdateSubscription = gql`
  subscription DistributionOnUpdate($accountId: ID!) {
    distributionOnUpdate(accountId: $accountId) {
      success
      distribution {
        __typename
        id
        name
        domainName
        pricingRegion
        status
      }
    }
  }
`

export const ContextProvider = ({ children }) => {
  // Managed by route handler
  const [activeDist, setActiveDist] = useState(null)
  const [activeOrigin, setActiveOrigin] = useState(null)
  const [activeCache, setActiveCache] = useState(null)

  const [spinner, setSpinner] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  // Managed by urql
  const [currentDist, setCurrentDist] = useState({})
  const [currentDistFetching, setCurrentDistFetching] = useState(false)
  const [reloadCurrentDist, setReloadCurrentDist] = useState(false)
  const [reloadMe, setReloadMe] = useState(false)
  const [me, setMe] = useState({})

  const [distributions, setDistributions] = useState([])
  const [distributionsFetching, setDistributionsFetching] = useState(false)

  // Managed by Amplify
  const { identity } = useAuthContext()

  /* DISTRIBUTIONS */
  let [distributionListResult, reexecuteDistributionsQuery] = useQuery({
    query: DistributionListQuery,
    pause: config.local,
  })
  if (config.local) {
    distributionListResult = dummyData.distributionList
  }
  let [meResult, reexecuteMeQuery] = useQuery({
    query: MeQuery,
    pause: config.local,
  })
  if (config.local) {
    setMe(dummyData.me)
  }
  useEffect(() => {
    setMe(meResult.data?.me)
  }, [meResult])
  const isSubscription =
    meResult.data?.me?.account?.subscription?.status === 'ACTIVE'

  const [subscriptionResult] = useSubscription({
    query: DistributionOnUpdateSubscription,
    variables: {
      accountId: identity.accountId,
    },
    pause: config.local || distributionListResult.fetching,
  })

  const { data: queryData } = distributionListResult
  const { data: subscriptionData } = subscriptionResult

  useEffect(() => {
    setDistributionsFetching(distributionListResult.fetching)
  }, [distributionListResult.fetching])

  useEffect(() => {
    const items = queryData?.distributionList?.items || []
    const updatedItem = subscriptionData?.distributionOnUpdate?.distribution

    setDistributions(
      items.map(item => (item.id === updatedItem?.id ? updatedItem : item))
    )
  }, [
    queryData?.distributionList?.items,
    subscriptionData?.distributionOnUpdate?.distribution,
  ])

  useEffect(() => {
    setReloadCurrentDist(true)
  }, [subscriptionData])

  useEffect(() => {
    if (reloadMe) {
      reexecuteMeQuery({ requestPolicy: 'network-only' })
      setReloadMe(false)
    }
  }, [reloadMe, reexecuteMeQuery])

  useEffect(() => {
    if (!config.local && reloadCurrentDist) {
      reexecuteDistributionsQuery({ requestPolicy: 'network-only' })
      setReloadCurrentDist(false)
    }
  }, [reloadCurrentDist, reexecuteDistributionsQuery])
  /* END DISTRIBUTIONS */

  /* DISTRIBUTION */
  const usageMetric = ['usage', 'unit', 'price']
  let [distributionByIdResult, reexecuteDistributionQuery] = useQuery({
    pause: true,
    query: buildQueryById('distributionById', [
      '__typename',
      'id',
      'name',
      'domainName',
      'defaultDomainName',
      'pricingRegion',
      'status',
      {
        usage: [
          'id',
          'date',
          { dataIn: usageMetric },
          { dataOut: usageMetric },
          { euNaDataOut: usageMetric },
          { restDataOut: usageMetric },
          { requests: usageMetric },
          { invalidations: usageMetric },
          'totalPrice',
          'currencyCode',
        ],
      },
      {
        origins: [
          '__typename',
          'id',
          'name',
          'domainName',
          'path',
          'protocolPolicy',
        ],
      },
      {
        domain: [
          '__typename',
          'id',
          'name',
          'status',
          { records: ['name', 'cname', 'value', 'status'] },
        ],
      },
      {
        caches: [
          '__typename',
          'id',
          'originId',
          'originName',
          'path',
          'policy',
          'ttl',
          'compression',
          'httpRedirection',
          'httpMethods',
          { headerForwarding: ['type', 'values'] },
          { queryStringForwarding: ['type', 'values'] },
          { cookieForwarding: ['type', 'values'] },
          'precedence',
        ],
      },
    ]),
    variables: {
      id: activeDist,
    },
  })
  if (config.local) {
    distributionByIdResult = dummyData.distributionById
  }

  useEffect(() => {
    setCurrentDistFetching(distributionByIdResult.fetching)
  }, [distributionByIdResult.fetching])

  useEffect(() => {
    if (
      !config.local &&
      activeDist !== null &&
      (reloadCurrentDist || activeDist)
    ) {
      reexecuteDistributionQuery({ requestPolicy: 'network-only' })
      if (reloadCurrentDist) {
        setReloadCurrentDist(false)
      }
    }
  }, [reloadCurrentDist, activeDist, reexecuteDistributionQuery])

  useEffect(() => {
    const dist = distributionByIdResult?.data?.distributionById || {}
    setCurrentDist(dist)
    if (queryData?.distributionList?.items) {
      setDistributions(
        queryData?.distributionList?.items?.map(d => {
          if (d.id === dist.id && d.status !== dist.status) {
            return {
              ...d,
              status: dist.status,
            }
          } else {
            return d
          }
        })
      )
    }
  }, [
    distributionByIdResult?.data?.distributionById,
    queryData?.distributionList?.items,
  ])
  /* END DISTRIBUTION */

  useEffect(() => {
    setIsLoading(
      meResult.fetching ||
        distributionListResult.fetching ||
        distributionByIdResult.fetching
    )
  }, [meResult, distributionListResult, distributionByIdResult])

  // Current Distribution

  useEffect(() => {
    /*const distribution = distributions.filter(
      distribution => distribution.id === activeDist
    )[0]
    setCurrentDist(distribution)
    console.log(distribution)*/
  }, [activeDist, distributions])

  return (
    <StateContext.Provider
      value={{
        activeDist,
        setActiveDist,
        activeOrigin,
        setActiveOrigin,
        activeCache,
        setActiveCache,
        distributions,
        setDistributions,
        distributionsFetching,
        currentDist,
        setCurrentDist,
        currentDistFetching,
        setReloadCurrentDist,
        setReloadMe,
        me,
        reloadMe,
        isSubscription,
        spinner,
        setSpinner,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
